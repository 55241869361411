import React from 'react'
import { Pane, UnorderedList, ListItem, Image } from '@muhsis/ui'

import { Layout, SEO, BackgroundImageGrid } from '../components'
import { Header, Footer, SpotlightHeading, Support } from '../blocks'
import {
  imgSecurityCloudflare,
  imgSecurityAzure,
  imgSecurityAws,
} from '../assets/images'

const SecurityPage = () => (
  <Layout>
    <SEO
      title="Güvenlik"
      keywords={[
        `muhsis veri güvenliği`,
        `web tabanlı ön muhasebe`,
        `muhasebe`,
        `web muhasebe programı`,
        `online muhasebe programı`,
      ]}
    />
    <BackgroundImageGrid>
      <Header />
      <Pane marginTop="2.5rem">
        <SpotlightHeading
          icon="lock"
          title="Şirketinizin Finansal Bilgileri Güvende"
          description="Muhsis'te veri ve uygulama güvenliği en çok dikkat ettiğimiz konuların başında gelir. Barındırma ve teknik alt yapımız ile her zaman en yüksek seviyede güvenlik sağlıyoruz. Bu alanda, dünyadaki son gelişmeleri sürekli takip ederek, sistemimizde sürekli gerekli güncellemeleri yapıyoruz."
        />
      </Pane>

      <Pane marginY="3rem" maxWidth={400} marginX="auto">
        <UnorderedList size={500} icon="tick">
          <ListItem>
            256 Bit SSL Sertifikası ile kullanıcı ve sistem arasında veri
            şifreleme yapılmaktadır.
          </ListItem>
          <ListItem>
            Sizin tarafınızdan davet edilmediği/izin verilmediği sürece, Muhsis
            personeli dahil, hiç kimse verilerinize erişemez.
          </ListItem>
          <ListItem>
            Uzun süre açık kalan oturumlar otomatik olarak kapatılmaktadır.
          </ListItem>
          <ListItem>
            Uygulamalarımız, Microsoft Azure üzerinde barındırılmaktadır.
          </ListItem>
          <ListItem>
            Uygulamalarımız her zaman Cloudflare ile saldırılara karşı koruma
            altındadır.
          </ListItem>
          <ListItem>
            Olası veri kayıplarına karşı, tüm verileriniz birden fazla yere
            günlük olarak yeedeklenmektedir
          </ListItem>
        </UnorderedList>
      </Pane>

      <Pane
        background="#F5FAFE"
        marginTop="4rem"
        paddingY="5rem"
        textAlign="center"
      >
        <Image
          src={imgSecurityCloudflare}
          alt="Muhsis uygulamaları, Cloudflare ile saldırılara karşı korunmaktadır."
          height={90}
          marginRight="2rem"
        />
        <Image
          src={imgSecurityAzure}
          alt="Muhsis uygulamaları, Microsoft Azure teknolojileri ile barındırılmaktadır."
          height={60}
          marginRight="3rem"
        />
        <Image
          src={imgSecurityAws}
          alt="Muhsis uygulamaları, Microsoft Azure teknolojileri ile barındırılmaktadır."
          height={50}
        />
      </Pane>
    </BackgroundImageGrid>

    <Support />
    <Footer />
  </Layout>
)
export default SecurityPage
